export const languages = [
  {
    label: 'English',
    value: 'en',
    image: '../assets/english.png'
  },
  {
    label: 'Portuguese - BR',
    value: 'pr',
    image: '../assets/portugese.png'
  },
  {
    label: 'Spanish',
    value: 'es',
    image: '../assets/spanish.jpg'
  }
]
