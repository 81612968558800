import Logo from 'atoms/Logo'
import logo from 'logo.svg'
import React from 'react'
import styled from 'styled-components'
import { withAppInsights } from 'util/appInsights'

export const Home = withAppInsights('Home', () => {
  return (
    <HomeWrapper>
      <LogoImage>
        <img
          src={logo}
          onError={ref => (ref.target.src = logo)}
          alt='App icon'
        />
      </LogoImage>{' '}
      <div className='no-result'>
        <Logo />
      </div>
      <div className='footer'>
        <p>Social</p>
        <i>Xperience</i>
        <span>|</span>
        <i>Xploring</i>
        <span>|</span>
        <i>Xposure</i>
      </div>
    </HomeWrapper>
  )
})

const LogoImage = styled.div`
  background: black;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 15px;
  box-sizing: border-box;

  @media only screen and (min-width: 768px) {
    img {
      max-width: 90px;
    }
  }

  @media (min-width: 1281px) {
    img {
      max-width: 60px;
    }
  }
`
const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  color: #ffffff;
  flex-direction: column;
  text-align: center;
  background: rgb(43 43 43 / 50%);
  height: 100vh;
  overflow: hidden;
  justify-content: center;

  .no-result {
    background: #f00d7f;
    padding: 25px;
    border-radius: 35px;
    max-width: 50%;
    margin: 0 auto 50px;
    overflow: hidden;

    img {
      width: 200px;
    }

    svg {
      width: 100vw;
      fill: #000000;
      max-width: 100%;
      margin: 0;
    }
  }

  .footer {
    margin-top: 25px;
    font-size: 25px;
    line-height: 2rem;
    color: white;
    overflow: hidden;

    @media only screen and (min-width: 768px) {
      font-size: 4rem;
      line-height: 8rem;
    }

    @media (min-width: 1281px) {
      font-size: 1rem;
      line-height: 2rem;
    }

    i {
      font-style: italic;
    }

    span {
      color: white;
      margin: 0 10px;
      font-weight: 600;
    }

    p {
      padding: 0;
      font-style: italic;
      text-transform: uppercase;
    }
  }
`
