import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    detection: options,
    debug: false,
    fallbackLng: 'en',
    whitelist: ['en', 'pr', 'es'],
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    },
    ns: ['common'],
    defaultNS: 'common',
    supportedLngs: ['en', 'pr'],
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`
    }
  })

export default i18n
