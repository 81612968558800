import { Menu } from 'organisms/Menu'
import { Home } from 'pages/Home'
import { RootPage } from 'pages/RootPage'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Route, Router } from 'react-router-dom'
import styled from 'styled-components'
import { AppContext } from 'util/contexts'
import history from 'util/history'
import { languages } from 'util/languages'
import { usePageholderCacheState } from 'util/storage'
import './i18n'

const queryClient = new QueryClient()

export const App = () => {
  const [xPagesPointer, setXPagesPointer] = useState([])
  const [queryValue, setQueryValue] = useState({})
  const [titleProps, setTitleProps] = useState({})
  const [searchFocus, setSearchFocus] = useState(false)
  const [mainFilter, setMainFilter] = useState(false)
  const [cache, setCache] = usePageholderCacheState()
  const [appProperties, setAppProperties] = useState({
    background: '',
    name: '',
    icon: '',
    description: ''
  })
  const [isHome, setToHome] = useState(false)
  const { t, i18n } = useTranslation()

  const handleLanguageChange = lang => {
    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    i18n.changeLanguage(window.localStorage.getItem('language'))
  }, [i18n])
  const screenWMax = window.screen.availWidth > 425 ? '425px' : 'initial'
  const [defaultLang, setLanguage] = useState(languages[0])

  return (
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <Wrapper background={appProperties?.background} size={screenWMax}>
          <Route
            path='/:appShortName'
            render={({
              match: {
                params: { appShortName }
              }
            }) => (
              <AppContext.Provider
                value={{
                  appShortName,
                  setXPagesPointer,
                  xPagesPointer,
                  queryValue,
                  setQueryValue,
                  titleProps,
                  setTitleProps,
                  searchFocus,
                  setSearchFocus,
                  mainFilter,
                  setMainFilter,
                  cache,
                  setCache,
                  setAppProperties,
                  appProperties,
                  isHome,
                  setToHome,
                  handleLanguageChange,
                  t,
                  setLanguage,
                  defaultLang
                }}
              >
                <RootPage />
                <Menu />
              </AppContext.Provider>
            )}
          />
          <Route exact path='/' render={() => <Home />} />
        </Wrapper>
      </Router>
    </QueryClientProvider>
  )
}

const Wrapper = styled.div`
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('${props => props.background}');
  max-width: ${props => props.size};
  margin: 0 auto;
  position: relative;

  &::before {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.35);
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
  }
`
