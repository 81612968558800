import { MainViewHeader } from 'atoms/MainViewHeader'
import { StatusIndicator } from 'atoms/StatusIndicator'
import { UnderConstruction } from 'molecules/UnderConstruction'
// import queryString from 'query-string'
import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react'
import { useParams } from 'react-router-dom'
import { withAppInsights } from 'util/appInsights'
import { AppContext } from 'util/contexts'
import { useMainViewQuery } from 'util/queries'
import { scrollToTop } from 'util/useScrollTo'

const ListView = lazy(() => import('organisms/ListView'))
export const MainViewPage = withAppInsights('Main View Page', () => {
  const { shareCode } = useParams()
  const { queryValue, appProperties, t } = useContext(AppContext)
  const take = 30
  const {
    data,
    status,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    error
  } = useMainViewQuery({
    take,
    query: queryValue?.query,
    advancedQuery: queryValue?.advancedQuery,
    mediaTypeId: queryValue?.mediaTypeId,
    sort: queryValue?.sort,
    customPageId: queryValue?.customPageId,
    shareCode: shareCode
  })

  useEffect(() => {
    status && scrollToTop()
  }, [status])

  const customPages = useMemo(
    () => data?.flatMap(page => page.data.posts)?.flatMap(posts => posts),
    [data]
  )

  const statusPage = useMemo(
    () => data?.map(page => page.status)?.map(i => i)[0],
    [data]
  )

  const observer = useRef()

  const lastItemNode = useCallback(
    node => {
      if (isLoading) return
      if (observer?.current) observer.current.disconnect()
      // eslint-disable-next-line
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          hasNextPage && fetchNextPage()
        }
      })
      if (node) observer.current.observe(node)
    },
    [fetchNextPage, hasNextPage, isLoading]
  )

  return (
    <>
      <MainViewHeader isMainView />

      {isLoading ? (
        <StatusIndicator
          status={status}
          viewPortHeight
          loadingText={t('loading app name', {
            name: appProperties.name || ''
          })}
          translated
        />
      ) : (
        <>
          {statusPage === 204 ? (
            <div style={{ height: '100vh' }}>
              <UnderConstruction />
            </div>
          ) : (
            <Suspense
              fallback={<StatusIndicator status='loading' viewPortHeight />}
            >
              <ListView
                customPages={customPages}
                isFetchingNextPage={isFetchingNextPage}
                error={error}
                getNext={lastItemNode}
                hasNextPage={hasNextPage}
                shareCode={shareCode}
              />
            </Suspense>
          )}
        </>
      )}
    </>
  )
})
