import {
  ReactPlugin,
  withAITracking
} from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import history from './history'

const reactPlugin = new ReactPlugin()
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history }
    }
  }
})
ai.loadAppInsights()

export const { appInsights } = ai
export const withAppInsights = (componentName, Component) =>
  process.env.NODE_ENV === 'test'
    ? Component
    : withAITracking(reactPlugin, Component, componentName)
