import { Icon } from 'atoms/Icon'
import PropTypes from 'prop-types'
import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { AppContext } from 'util/contexts'

export const StatusIndicator = ({
  status,
  loadingText = 'loading',
  errorText = 'generic-error',
  dark,
  viewPortHeight,
  heightAuto,
  search,
  translated = false
}) => {
  const heightVPVl = heightAuto ? 'auto' : viewPortHeight ? '100vh' : '100%'
  const { appProperties, t } = useContext(AppContext)

  const bgImg = useMemo(() => appProperties?.background, [appProperties])
  return (
    <>
      {status === 'loading' && (
        <StatusWrapper
          dark={dark}
          heightVP={heightVPVl}
          bg={bgImg}
          search={search}
        >
          <div
            className='loading'
            style={{ borderColor: dark ? '#000000' : '#ffffff' }}
          />
          {translated ? (
            <span>{loadingText}</span>
          ) : (
            <span>{t(loadingText)}</span>
          )}
        </StatusWrapper>
      )}

      {status === 'error' && (
        <StatusWrapper>
          <Icon icon='timesCircle' />
          <span>{t(errorText)}</span>
        </StatusWrapper>
      )}
    </>
  )
}

const StatusWrapper = styled.div`
  display: flex;
  width: 100%;
  height: ${props => props?.heightVP};
  align-items: center;
  justify-content: center;
  color: ${props => (props.dark ? '#000000' : '#ffffff')};
  flex-direction: column;
  padding: 10px 0;

  span {
    margin: 15px 0 5px;
  }

  svg {
    width: 10%;
    max-width: 25px;
  }

  .loading {
    font-size: 0;
    width: 50px;
    height: 50px;
    border: 4px solid black;
    border-radius: 50%;
    position: relative;
    box-sizing: border-box;

    &::before {
      content: ' ';
      position: absolute;
      top: -4px;
      right: 0;
      left: -4px;
      margin: 0 auto;
      border-radius: 50%;
      border-top: 4px solid rgb(82, 82, 82);
      border-bottom: 4px solid transparent;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      animation: loading 1s linear infinite;
    }
  }

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

StatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
  loadingText: PropTypes.string,
  errorText: PropTypes.string,
  dark: PropTypes.bool,
  viewPortHeight: PropTypes.bool,
  heightAuto: PropTypes.bool
}
