import { Icon } from 'atoms/Icon'
import { MenuIcon } from 'atoms/MenuIcon'
import { AdvanceSearch } from 'molecules/AdvanceSearch'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { appInsights } from 'util/appInsights'
import { AppContext } from 'util/contexts'
import { isSafariMobile, size } from 'util/mediaBreakpoints'

const SearchBox = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    errors,
    setValue,
    formState: { isSubmitting, dirty }
  } = useForm()
  const history = useHistory()
  const { appShortName, t } = useContext(AppContext)
  const searchVl = watch('queryVl')
  const { setQueryValue, setSearchFocus } = useContext(AppContext)
  const [openAdvanceSearch, setOpenAdvanceSearch] = useState(false)
  const [inputFocus, setInputFocus] = useState(false)
  const [advanceQuery, setAdvanceQuery] = useState('')

  useEffect(() => {
    if (inputFocus) {
      setSearchFocus(true)
    }
  }, [inputFocus, setSearchFocus])

  const handleSubmitForm = vl => {
    if (openAdvanceSearch) {
      setAdvanceQuery(vl)
      appInsights.trackEvent({
        name: 'Advanced filter',
        properties: { searchedText: vl }
      })
    } else {
      setQueryValue({ query: vl })
      appInsights.trackEvent({
        name: 'Free text filter',
        properties: { searchedText: vl }
      })
    }
  }

  const onSubmit = data => {
    handleSubmitForm(data.queryVl)
  }

  const clearInput = () => {
    setAdvanceQuery('')
    reset()
    setValue('queryVl', '')
    setQueryValue({})
    history.push(`/${appShortName}/main-view`)
  }

  const handleOpenAdvanceSearch = () => {
    setOpenAdvanceSearch(true)
    reset()
    setQueryValue({})
    setSearchFocus(false)
  }

  const handleCloseAdvanceSearch = () => {
    setAdvanceQuery('')
    setOpenAdvanceSearch(false)
    reset()
    setQueryValue({})
    history.push(`/${appShortName}/main-view`)
  }

  const isDisabled = useMemo(
    () => searchVl?.length < 3 || searchVl === undefined,
    [searchVl]
  )

  const [countDirty, setCountDirty] = useState(0)
  useEffect(() => {
    if (dirty) {
      setCountDirty(vl => vl + 1)
    }
  }, [dirty, setCountDirty])
  return (
    <FormSearch
      onSubmit={handleSubmit(onSubmit)}
      size={size.mobileL}
      moveUp={inputFocus && isSafariMobile}
    >
      {openAdvanceSearch && <AdvanceSearch values={advanceQuery} />}
      <Label>
        <Input
          type='text'
          placeholder={
            openAdvanceSearch
              ? t('Social media account name or #')
              : t('Free text search')
          }
          id='searchInput'
          name='queryVl'
          autoComplete='off'
          onFocus={() => setInputFocus(!inputFocus)}
          error={errors?.queryVl && countDirty >= 1}
          ref={register({ required: true })}
          onKeyPress={event => {
            if (event.key === 'Enter' && !isDisabled) {
              handleSubmitForm(searchVl)
            }
          }}
        />
        {searchVl ? (
          <>
            <CleanInputIcon onClick={clearInput} id='cleanButton'>
              <Icon icon='times' fill='#313131' />
            </CleanInputIcon>
            <LineSeparator />
          </>
        ) : (
          ''
        )}

        <SearchButton type='submit' disabled={isDisabled} id='searchButton'>
          {isSubmitting === 'submiting' ? (
            <Loading />
          ) : (
            <MenuIcon icon='search' />
          )}
        </SearchButton>
      </Label>
      {openAdvanceSearch ? (
        <SearchAdvance
          onClick={handleCloseAdvanceSearch}
          id='searchAdvanceCloseButton'
        >
          <Icon icon='times' fill='#ffffff' />
        </SearchAdvance>
      ) : (
        <SearchAdvance
          onClick={() => handleOpenAdvanceSearch()}
          id='searchAdvanceOpenButton'
        >
          <Icon icon='externalAlt' fill='#ffffff' />
        </SearchAdvance>
      )}
    </FormSearch>
  )
}

export default SearchBox

const Loading = styled.div`
  font-size: 0;
  width: 21px;
  height: 21px;
  border: 2px solid #ffff;
  border-radius: 50%;
  position: relative;
  box-sizing: border-box;
  top: -4px;

  &::before {
    content: ' ';
    position: absolute;
    top: -2px;
    right: 0;
    left: -2px;
    margin: 0 auto;
    border-radius: 50%;
    border-top: 2px solid rgb(82, 82, 82);
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: loading 1s linear infinite;
  }

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

const FormSearch = styled.form`
  align-items: center;
  background: #1f1f1f;
  color: #ffffff;
  display: flex;
  height: auto;
  justify-content: space-evenly;
  margin: 0 auto;
  max-width: ${props => props.size};
  width: 100%;
  position: ${props => (props.moveUp ? 'absolute' : 'fixed')};
  bottom: ${props => (props.moveUp ? '40px' : '3rem')};
  padding: 10px 0;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    bottom: ${props => (props.moveUp ? '40px' : '3rem')};
    min-height: 3rem;
  }
`

const Label = styled.div`
  position: relative;
  width: 85%;
  display: flex;
`

const SearchButton = styled.button`
  background: no-repeat;
  border: 0;
  color: #ffffff;
  opacity: ${props => (props.disabled ? '0.2' : '1')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  position: absolute;
  right: 5px;
  bottom: 4px;
  padding: 0;
  border: 0;
  padding: 0;

  svg {
    width: 20px;
  }
`

const CleanInputIcon = styled.div`
  align-items: center;
  background: #636363;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 15px;
  position: absolute;
  right: 41px;
  top: 10px;
  width: 15px;
  border: 0;
  padding: 0;

  svg {
    height: 7px;
  }
`

const LineSeparator = styled.div`
  width: 1px;
  height: 25px;
  background: #fff;
  display: block;
  position: absolute;
  right: 33px;
  top: 5px;
`
const SearchAdvance = styled.button`
  width: 20px;
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
`

const Input = styled.input`
  background: #313131;
  color: #ffffff;
  border: 0;
  border-radius: 5px;
  font-size: 0.9rem;
  height: 1.5rem;
  padding: 5px 40px 5px 10px;
  width: 100%;
  position: relative;

  @media (max-width: 768px) {
    height: 1.8rem;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    font-size: 16px;
  }

  &:focus {
    border: 1px solid #636363;
    box-shadow: 0px 0px 4px #989898;
  }

  &::-webkit-input-placeholder {
    color: #636363;
    font-weight: 100;
    font-style: italic;
  }
  &:-moz-placeholder {
    color: #636363;
    font-weight: 100;
    font-style: italic;
  }
  &::-moz-placeholder {
    color: #636363;
    font-weight: 100;
    font-style: italic;
  }
  &:-ms-input-placeholder {
    color: #636363;
    font-weight: 100;
    font-style: italic;
  }
`
