import PropTypes from 'prop-types'
import React from 'react'

export const ColorMediaIcon = ({ icon, ...props }) =>
  ({
    facebook: (
      <svg viewBox='5 0 20 20' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill='#3b5998'
          {...props}
          d='M20 3H4a1 1 0 00-1 1v16a1 1 0 001 1h8.615v-6.96h-2.338v-2.725h2.338v-2c0-2.325 1.42-3.592 3.5-3.592.699-.002 1.399.034 2.095.107v2.42h-1.435c-1.128 0-1.348.538-1.348 1.325v1.735h2.697l-.35 2.725h-2.348V21H20a1 1 0 001-1V4a1 1 0 00-1-1z'
        />
      </svg>
    ),
    twitter: (
      <svg viewBox='0 0 448 512' xmlns='http://www.w3.org/2000/svg'>
        <linearGradient
          id='linearTwitter'
          x1='0%'
          y1='0%'
          x2='100%'
          y2='0%'
          gradientTransform='rotate(45)'
        >
          <stop offset='1%' stopColor='#66757f' />
          <stop offset='80%' stopColor='#00ACEE' />
          <stop offset='99%' stopColor='#36D8FF' />
          <stop offset='100%' stopColor='#f5f8fa' />
        </linearGradient>
        <path
          fill='#00ACEE'
          d='M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 01-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z'
        />
      </svg>
    ),
    instagram: (
      <svg viewBox='0 0 448 512' xmlns='http://www.w3.org/2000/svg'>
        <linearGradient
          id='linear'
          x1='0%'
          y1='0%'
          x2='100%'
          y2='0%'
          gradientTransform='rotate(45)'
        >
          <stop offset='0%' stopColor='#405de6' />
          <stop offset='25%' stopColor='#5851db' />
          <stop offset='50%' stopColor='#833ab4' />
          <stop offset='80%' stopColor='#c13584' />
          <stop offset='100%' stopColor='#e1306c' />
        </linearGradient>
        <path
          fill='url(#linear)'
          d='M224 202.66A53.34 53.34 0 10277.36 256 53.38 53.38 0 00224 202.66zm124.71-41a54 54 0 00-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31 6.43a54 54 0 00-30.41 30.41c-8.28 21-6.43 71.05-6.43 94.33s-1.85 73.27 6.47 94.34a54 54 0 0030.41 30.41c21 8.29 71 6.43 94.31 6.43s73.24 1.93 94.3-6.43a54 54 0 0030.41-30.41c8.35-21 6.43-71.05 6.43-94.33s1.92-73.26-6.43-94.33zM224 338a82 82 0 1182-82 81.9 81.9 0 01-82 82zm85.38-148.3a19.14 19.14 0 1119.13-19.14 19.1 19.1 0 01-19.09 19.18zM400 32H48A48 48 0 000 80v352a48 48 0 0048 48h352a48 48 0 0048-48V80a48 48 0 00-48-48zm-17.12 290c-1.29 25.63-7.14 48.34-25.85 67s-41.4 24.63-67 25.85c-26.41 1.49-105.59 1.49-132 0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61 0-132 1.29-25.63 7.07-48.34 25.85-67s41.47-24.56 67-25.78c26.41-1.49 105.59-1.49 132 0 25.63 1.29 48.33 7.15 67 25.85s24.63 41.42 25.85 67.05c1.49 26.32 1.49 105.44 0 131.88z'
        />
      </svg>
    ),
    youtube: (
      <svg viewBox='0 0 448 512' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill='#ff0000'
          {...props}
          d='M186.8 202.1l95.2 54.1-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9 7.6-28.5 7.6-88.1 7.6-88.1z'
        />
      </svg>
    ),
    socialXP: (
      <svg
        id='Lager_1'
        data-name='Lager 1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 607.85 607.85'
        fill={props.fill || '#cccccc'}
      >
        <title>Social_XP_Logo</title>
        <path
          d='M469.54,223.23H352.26l-59,71.27H469.54c12.5,0,26.42,16,26.42,38.94S482,372.38,469.54,372.38H420.6l-58.06,71.27h107c53.87,0,97.69-49.44,97.69-110.21S523.41,223.23,469.54,223.23Z'
          transform='translate(-12.69 -9.98)'
        />
        <polygon points='275.05 496.2 438.81 299.77 341.44 299.77 178.5 495.71 275.05 496.2' />
        <polygon points='224.54 416.07 269.82 361.62 236.44 321.83 327.51 213.25 236.57 213.25 190.97 267.61 145.37 213.25 54.42 213.25 145.49 321.83 53.3 432.23 55.92 432.19 143.87 432.19 190.97 376.04 224.54 416.07' />
        <path
          d='M167.77,200.37c-9,10.76-19.22,14.74-31.68,14.74h-70L84,193.7h60.82l12.5-15H125.46c-15.64,0-13.87-6.44-7-14.63L141.08,137c9.27-11.12,19.57-15.33,32.17-15.33h60.86L216.24,143H164.52L152.6,157.31h31.58c13.29,0,15.62,4.68,6.24,15.91Z'
          transform='translate(-12.69 -9.98)'
        />
        <path
          d='M256.16,200.37c-8.69,10.41-18.67,14.74-31.27,14.74H187.66c-11.91,0-15.55-4.09-6.67-14.74l53.31-63.89c8.3-9.95,18.77-14.86,31.36-14.86H302.9c12,0,15.45,4.21,6.56,14.86Zm-23-6.67,42.17-50.55H257.25L215.07,193.7Z'
          transform='translate(-12.69 -9.98)'
        />
        <path
          d='M308.8,215.11H275.16c-12.6,0-15.64-4-6.66-14.74l53.31-63.89c9-10.77,18.76-14.86,31.36-14.86h33.64L368.94,143l-24.19.12L302.58,193.7h24.09Z'
          transform='translate(-12.69 -9.98)'
        />
        <path
          d='M346.89,215.11H318.38l78-93.49H424.9Z'
          transform='translate(-12.69 -9.98)'
        />
        <path
          d='M433.4,215.11H406L427,190H408.74l-21,25.16H359.23L424.45,137c9.27-11.12,19.71-15.33,32.31-15.33h54.65Zm11.28-46.34L466.16,143H447.89l-21.48,25.74Z'
          transform='translate(-12.69 -9.98)'
        />
        <path
          d='M529.22,215.11H444.61l78-93.49h28.51L491,193.7h56.09Z'
          transform='translate(-12.69 -9.98)'
        />
      </svg>
    )
  }[icon])

ColorMediaIcon.propTypes = {
  icon: PropTypes.string.isRequired
}
