import { useContext } from 'react'
import { AppContext } from 'util/contexts'
import { buildRoute } from './buildRoute'
import * as routes from './routes'

export const useRoutes = () => {
  const { appShortName } = useContext(AppContext)

  return new Proxy(routes, {
    get: (target, name) =>
      name in target
        ? params => buildRoute(target[name](params || {}), { appShortName })
        : undefined
  })
}
