import hexagonShape from 'hexagon.svg'
import React from 'react'
import styled from 'styled-components'

export const HexagonImg = ({ background, borderColor }) => {
  return (
    <>
      {background ? (
        <HexaDiv>
          <TopArrow bdColor={borderColor || '#fff'} />
          <Rect bdColor={borderColor || '#fff'} />
          <BottomArrow bdColor={borderColor || '#fff'} />
          <HoldImg
            bg={background}
            hexagonShape={hexagonShape}
            bdColor={borderColor || '#fff'}
          />
        </HexaDiv>
      ) : (
        ''
      )}
    </>
  )
}

const HexaDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const TopArrow = styled.div`
  width: 0;
  border-bottom: 10px solid ${props => props.bdColor};
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
`

const BottomArrow = styled.div`
  width: 0;
  border-top: 10px solid ${props => props.bdColor};
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
`

const Rect = styled.div`
  height: 20px;
  width: 40px;
  background: ${props => props.bdColor};
`

const HoldImg = styled.div`
  width: 36px;
  height: 36px;
  background-image: url('${props => props.bg}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  mask-image: url(${props => props.hexagonShape});
  mask-repeat: no-repeat;
  mask-size: 100% 101%;
  mask-position: center;
`
