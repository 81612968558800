import biri from 'biri'
import { useEffect } from 'react'
import { createLocalStorageStateHook } from 'use-local-storage-state'
import { v4 as uuidv4 } from 'uuid'

export const useClientIdState = createLocalStorageStateHook('clientId')

export const useClientId = () => {
  const [clientId, setClientId] = useClientIdState()

  useEffect(() => {
    if (!clientId) {
      generateClientId().then(clientId => setClientId(clientId))
    }
    // eslint-disable-next-line
  }, [clientId])

  return clientId
}

const generateClientId = async () => {
  let clientId

  try {
    clientId = await biri()
  } catch (err) {
    clientId = uuidv4()
  }

  return clientId
}
