import { MenuIcon } from 'atoms/MenuIcon'
import { Spinner } from 'atoms/Spinner'
import SearchBox from 'organisms/SearchBox'
import React, {
  lazy,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import { appInsights } from 'util/appInsights'
import { AppContext } from 'util/contexts'
import { size } from 'util/mediaBreakpoints'

// const SearchBox = lazy(() => import('organisms/SearchBox'))
const SettingsMenu = lazy(() => import('organisms/SettingsMenu'))

export const Menu = () => {
  const history = useHistory()
  const {
    appShortName,
    xPagesPointer,
    setQueryValue,
    setMainFilter,
    setToHome
  } = useContext(AppContext)

  const [openSearch, setopenSearch] = useState(false)
  const hasPointer = useMemo(() => xPagesPointer?.length, [xPagesPointer])
  const [openSettings, setOpenSettings] = useState(false)
  const toogleOpenSettings = () => {
    if (!openSettings) {
      appInsights.trackEvent({
        name: 'Open Settings'
      })
    }
    openSearch && setopenSearch(false)
    openSettings ? setActiveMenu('home') : setActiveMenu('settings')
    setOpenSettings(!openSettings)
  }
  const toogleOpenSearch = () => {
    openSearch ? setActiveMenu('home') : setActiveMenu('search')
    setopenSearch(!openSearch)
  }
  const handleSearch = () => {
    history.push(`/${appShortName}/main-view`)
    toogleOpenSearch()
  }
  const [activeMenu, setActiveMenu] = useState('home')

  useEffect(() => {
    if (openSearch) {
      setMainFilter(false)
      setQueryValue({})
    } else {
      setQueryValue({})
    }
  }, [openSearch, setQueryValue, setMainFilter])

  const menuArray = [
    {
      icon: 'search',
      onclick: handleSearch
    },
    {
      icon: 'home',
      onclick: () => {
        setopenSearch(false)
        setActiveMenu('home')
        setToHome(true)
      }
    },
    { icon: 'settings', onclick: toogleOpenSettings }
  ]

  if (hasPointer) menuArray.splice(0, 2)

  const MenuItem = ({ icon, onclick }) => {
    return (
      <CustomLink active={icon === activeMenu}>
        <span onClick={onclick}>
          <MenuIcon icon={icon} />
        </span>
      </CustomLink>
    )
  }

  return (
    <MenuPanel size={size.mobileL}>
      {menuArray.map(props => (
        <MenuItem key={props.icon} {...props} />
      ))}
      <CSSTransition
        in={openSearch}
        timeout={300}
        classNames='settings'
        unmountOnExit
      >
        {/* <Suspense fallback={<Spinner />}>
          <SearchBox />
        </Suspense> */}
        <SearchBox />
      </CSSTransition>

      <CSSTransition
        in={openSettings}
        timeout={300}
        classNames='settings'
        unmountOnExit
      >
        <Suspense
          fallback={<Spinner style={{ left: 'unset', right: '26%' }} />}
        >
          <SettingsMenu toogleOpenSettings={toogleOpenSettings} />
        </Suspense>
      </CSSTransition>
    </MenuPanel>
  )
}

const MenuPanel = styled.div`
  align-items: center;
  background: #080808;
  bottom: -1px;
  color: #ffffff;
  display: flex;
  max-width: ${props => props.size};
  position: fixed;
  width: 100%;
  z-index: 2;
  border-top: 2px solid #1a1a1a;
  height: auto;
  justify-content: center;
`

const CustomLink = styled.div`
  flex: 0 0 20%;
  text-align: center;
  position: relative;
  min-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  @media (max-width: 768px) {
    min-height: 3.5rem;
  }

  svg {
    width: 1.8rem;
    height: 1.8rem;
  }

  @media (max-width: 768px) {
    width: 2rem;
    height: 2rem;
  }

  ${({ active }) =>
    active &&
    css`
      &:before {
        content: '';
        border: 1px solid #ffffff;
        font-size: 0;
        position: absolute;
        top: 0;
        width: 50%;
      }
    `}
`
