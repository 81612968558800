import PropTypes from 'prop-types'
import React from 'react'

export const Icon = ({ icon, ...props }) =>
  ({
    about: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' {...props}>
        <path
          fill='#fff'
          {...props}
          d='M256,6C117.9,6,6,118,6,256c0,138.1,111.9,250,250,250s250-111.9,250-250C506,118,394.1,6,256,6z M256,116.9
	c23.4,0,42.3,19,42.3,42.3s-19,42.3-42.3,42.3s-42.3-19-42.3-42.3S232.6,116.9,256,116.9z M312.5,372.9c0,6.7-5.4,12.1-12.1,12.1
	h-88.7c-6.7,0-12.1-5.4-12.1-12.1v-24.2c0-6.7,5.4-12.1,12.1-12.1h12.1v-64.5h-12.1c-6.7,0-12.1-5.4-12.1-12.1v-24.2
	c0-6.7,5.4-12.1,12.1-12.1h64.5c6.7,0,12.1,5.4,12.1,12.1v100.8h12.1c6.7,0,12.1,5.4,12.1,12.1V372.9z'
        />
      </svg>
    ),
    arrowBack: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 148 254' {...props}>
        <path
          fill='#fff'
          {...props}
          d='M3.51,118.57,120.55,3.5a12,12,0,0,1,16.89,0l7.05,7a11.9,11.9,0,0,1,0,16.86L42.86,127,144.4,226.6a11.9,11.9,0,0,1,0,16.86l-7.05,7a12,12,0,0,1-16.89,0L3.42,135.43A12,12,0,0,1,3.51,118.57Z'
        />
      </svg>
    ),
    checkCircle: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' {...props}>
        <path
          fill='#fff'
          d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'
        />
      </svg>
    ),
    contentFilter: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' {...props}>
        <path
          fill='#fff'
          {...props}
          d='M482.5,6H29.5C8.6,6-1.9,31.3,12.9,46l180.6,180.6v201.2c0,7.6,3.7,14.8,10,19.2l78.1,54.7
	c15.4,10.8,36.9-0.1,36.9-19.2V226.6L499.1,46C513.8,31.3,503.4,6,482.5,6z'
        />
      </svg>
    ),
    login: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' {...props}>
        <path
          fill='#fff'
          {...props}
          d='M140,113.7l21.4-21.4c9.1-9.1,23.9-9.2,33.1-0.1l148.3,147.2c9.3,9.2,9.3,24.1,0,33.3L194.5,419.8
	c-9.2,9.1-23.9,9.1-33.1-0.1L140,398.3c-9.5-9.5-9.1-24.8,0.8-33.9l75.8-69.4H29.4C16.4,295,6,284.5,6,271.5v-31.3
	c0-13,10.4-23.4,23.4-23.4h187l-75.8-69.4C130.9,138.5,130.5,123.1,140,113.7L140,113.7z M506,349.8V162.3c0-51.8-42-93.8-93.8-93.8
	h-82c-6.4,0-11.7,5.3-11.7,11.7v39.1c0,6.4,5.3,11.7,11.7,11.7h82c17.3,0,31.3,14,31.3,31.3v187.5c0,17.3-14,31.3-31.3,31.3h-82
	c-6.4,0-11.7,5.3-11.7,11.7v39.1c0,6.4,5.3,11.7,11.7,11.7h82C464,443.5,506,401.5,506,349.8z'
        />
      </svg>
    ),
    history: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 590' {...props}>
        <path
          fill='#fff'
          {...props}
          d='M12.39,55.52H22.71A12.42,12.42,0,0,1,35.1,67.9v97.65A255.72,255.72,0,0,1,256.83,39c140.8.41,255.38,115.61,255.17,256.41C511.79,436.63,397.21,551,256,551A255.32,255.32,0,0,1,84.44,485a12.36,12.36,0,0,1-.52-18l7.33-7.33a12.46,12.46,0,0,1,17-.52A220,220,0,0,0,256,515.9c121.5,0,220.9-98.37,220.9-220.9,0-121.5-98.37-220.9-220.9-220.9A220.91,220.91,0,0,0,59.56,193.84H161a12.42,12.42,0,0,1,12.39,12.39v10.32A12.42,12.42,0,0,1,161,228.94H12.39A12.42,12.42,0,0,1,0,216.55V67.9A12.42,12.42,0,0,1,12.39,55.52Zm332,325.37,4.85-6.71a12.47,12.47,0,0,0-2.68-17.34l-74-53.68V150.48a12.42,12.42,0,0,0-12.39-12.39h-8.26a12.42,12.42,0,0,0-12.39,12.39V319.88l87.64,63.69a12.18,12.18,0,0,0,17.24-2.68Z'
        />
      </svg>
    ),
    signUp: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' {...props}>
        <path
          fill='#ccc'
          {...props}
          d='M493.5,218.5h-50v-50c0-6.9-5.6-12.5-12.5-12.5h-25c-6.9,0-12.5,5.6-12.5,12.5v50h-50
	c-6.9,0-12.5,5.6-12.5,12.5v25c0,6.9,5.6,12.5,12.5,12.5h50v50c0,6.9,5.6,12.5,12.5,12.5h25c6.9,0,12.5-5.6,12.5-12.5v-50h50
	c6.9,0,12.5-5.6,12.5-12.5v-25C506,224.1,500.4,218.5,493.5,218.5z M181,256c55.2,0,100-44.8,100-100S236.2,56,181,56
	S81,100.8,81,156S125.8,256,181,256z M251,281h-13c-17.3,8-36.6,12.5-57,12.5s-39.5-4.5-57-12.5h-13C53,281,6,328,6,386v32.5
	C6,439.2,22.8,456,43.5,456h275c20.7,0,37.5-16.8,37.5-37.5V386C356,328,309,281,251,281z'
        />
      </svg>
    ),
    tools: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' {...props}>
        <path
          fill='#fff'
          d='M501.1 395.7L384 278.6c-23.1-23.1-57.6-27.6-85.4-13.9L192 158.1V96L64 0 0 64l96 128h62.1l106.6 106.6c-13.6 27.8-9.2 62.3 13.9 85.4l117.1 117.1c14.6 14.6 38.2 14.6 52.7 0l52.7-52.7c14.5-14.6 14.5-38.2 0-52.7zM331.7 225c28.3 0 54.9 11 74.9 31l19.4 19.4c15.8-6.9 30.8-16.5 43.8-29.5 37.1-37.1 49.7-89.3 37.9-136.7-2.2-9-13.5-12.1-20.1-5.5l-74.4 74.4-67.9-11.3L334 98.9l74.4-74.4c6.6-6.6 3.4-17.9-5.7-20.2-47.4-11.7-99.6.9-136.6 37.9-28.5 28.5-41.9 66.1-41.2 103.6l82.1 82.1c8.1-1.9 16.5-2.9 24.7-2.9zm-103.9 82l-56.7-56.7L18.7 402.8c-25 25-25 65.5 0 90.5s65.5 25 90.5 0l123.6-123.6c-7.6-19.9-9.9-41.6-5-62.7zM64 472c-13.2 0-24-10.8-24-24 0-13.3 10.7-24 24-24s24 10.7 24 24c0 13.2-10.7 24-24 24z'
        />
      </svg>
    ),
    timesCircle: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' {...props}>
        <path
          fill='#fff'
          d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'
        />
      </svg>
    ),
    times: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 254 254' {...props}>
        <path
          fill='#fff'
          {...props}
          d='M248.55,243.62a11.81,11.81,0,0,0,0-16.62L150.21,127,248.5,27a11.81,11.81,0,0,0,0-16.62l-6.89-6.94a11.71,11.71,0,0,0-16.56,0l0,0L127,103.47l-98-99.93a11.71,11.71,0,0,0-16.56,0l0,0L5.45,10.47a11.81,11.81,0,0,0,0,16.62L103.66,127,5.5,226.91a11.81,11.81,0,0,0,0,16.62l6.89,6.94,0,0a11.71,11.71,0,0,0,16.56,0l98-99.93,98.06,100,0,0a11.71,11.71,0,0,0,16.56,0Z'
        />
      </svg>
    ),
    socialXP: (
      <svg
        id='Lager_1'
        data-name='Lager 1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 607.85 607.85'
        {...props}
      >
        <title>Social_XP_Logo</title>
        <path
          d='M469.54,223.23H352.26l-59,71.27H469.54c12.5,0,26.42,16,26.42,38.94S482,372.38,469.54,372.38H420.6l-58.06,71.27h107c53.87,0,97.69-49.44,97.69-110.21S523.41,223.23,469.54,223.23Z'
          transform='translate(-12.69 -9.98)'
        />
        <polygon points='275.05 496.2 438.81 299.77 341.44 299.77 178.5 495.71 275.05 496.2' />
        <polygon points='224.54 416.07 269.82 361.62 236.44 321.83 327.51 213.25 236.57 213.25 190.97 267.61 145.37 213.25 54.42 213.25 145.49 321.83 53.3 432.23 55.92 432.19 143.87 432.19 190.97 376.04 224.54 416.07' />
        <path
          d='M167.77,200.37c-9,10.76-19.22,14.74-31.68,14.74h-70L84,193.7h60.82l12.5-15H125.46c-15.64,0-13.87-6.44-7-14.63L141.08,137c9.27-11.12,19.57-15.33,32.17-15.33h60.86L216.24,143H164.52L152.6,157.31h31.58c13.29,0,15.62,4.68,6.24,15.91Z'
          transform='translate(-12.69 -9.98)'
        />
        <path
          d='M256.16,200.37c-8.69,10.41-18.67,14.74-31.27,14.74H187.66c-11.91,0-15.55-4.09-6.67-14.74l53.31-63.89c8.3-9.95,18.77-14.86,31.36-14.86H302.9c12,0,15.45,4.21,6.56,14.86Zm-23-6.67,42.17-50.55H257.25L215.07,193.7Z'
          transform='translate(-12.69 -9.98)'
        />
        <path
          d='M308.8,215.11H275.16c-12.6,0-15.64-4-6.66-14.74l53.31-63.89c9-10.77,18.76-14.86,31.36-14.86h33.64L368.94,143l-24.19.12L302.58,193.7h24.09Z'
          transform='translate(-12.69 -9.98)'
        />
        <path
          d='M346.89,215.11H318.38l78-93.49H424.9Z'
          transform='translate(-12.69 -9.98)'
        />
        <path
          d='M433.4,215.11H406L427,190H408.74l-21,25.16H359.23L424.45,137c9.27-11.12,19.71-15.33,32.31-15.33h54.65Zm11.28-46.34L466.16,143H447.89l-21.48,25.74Z'
          transform='translate(-12.69 -9.98)'
        />
        <path
          d='M529.22,215.11H444.61l78-93.49h28.51L491,193.7h56.09Z'
          transform='translate(-12.69 -9.98)'
        />
      </svg>
    ),
    externalAlt: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <path
          {...props}
          d='M326.83,47.61H488.2L464.46,23.86v161.4c0,12.41,10.92,24.34,23.74,23.74s23.74-10.45,23.74-23.74V23.86A24.09,24.09,0,0,0,488.2.12H326.83C314.43.12,302.5,11,303.09,23.86s10.39,23.74,23.74,23.74Z'
        />
        <path
          {...props}
          d='M237.21,308.5l26.65-26.65,64-64,77.39-77.41,66.89-66.9C483,62.69,494,52,504.64,41l.47-.47c8.78-8.79,9.44-24.93,0-33.6s-24.21-9.38-33.59,0L444.88,33.6l-64,64q-38.73,38.73-77.39,77.47L236.62,242c-10.86,10.86-21.84,21.55-32.52,32.53l-.47.47c-8.78,8.79-9.44,24.93,0,33.6s24.21,9.32,33.59-.06Z'
        />
        <path
          {...props}
          d='M464.52,292.18v138c0,7.42.59,15.2-.42,22.62.3-2.08.59-4.21.83-6.29a45.19,45.19,0,0,1-2.73,10c.77-1.9,1.6-3.8,2.37-5.7a45.41,45.41,0,0,1-5.46,9.44l3.74-4.81a46.72,46.72,0,0,1-7.66,7.66l4.81-3.74a43.92,43.92,0,0,1-9.44,5.46c1.9-.77,3.8-1.6,5.7-2.37a44.29,44.29,0,0,1-10,2.73c2.08-.3,4.21-.59,6.29-.83-4.87.65-9.91.42-14.84.42H110.5c-15.07,0-30.15.12-45.16,0a41.5,41.5,0,0,1-6.05-.42c2.08.3,4.21.59,6.29.83a45.17,45.17,0,0,1-10-2.73c1.9.77,3.8,1.6,5.7,2.37a45.4,45.4,0,0,1-9.44-5.46l4.81,3.74A46.72,46.72,0,0,1,49,455.42l3.74,4.81a43.93,43.93,0,0,1-5.46-9.44c.77,1.9,1.6,3.8,2.37,5.7a44.32,44.32,0,0,1-2.73-10c.3,2.08.59,4.21.83,6.29-.65-4.87-.42-9.91-.42-14.84V110.65c0-15.08-.12-30.16,0-45.17a41.53,41.53,0,0,1,.42-6.05c-.3,2.08-.59,4.21-.83,6.29a45.19,45.19,0,0,1,2.73-10c-.77,1.9-1.6,3.8-2.37,5.7A45.41,45.41,0,0,1,52.76,52L49,56.81a46.72,46.72,0,0,1,7.66-7.66l-4.81,3.74a43.92,43.92,0,0,1,9.44-5.46c-1.9.77-3.8,1.6-5.7,2.37a44.29,44.29,0,0,1,10-2.73c-2.08.3-4.21.59-6.29.83,6.29-.83,12.82-.42,19.11-.42H219.88c12.4,0,24.33-10.92,23.74-23.74S233.18,0,219.88,0H67.3C49.08,0,31.39,6.35,18.57,19.65A66.62,66.62,0,0,0,.06,66.07V426.28c0,11.22-.47,22.44,1.37,33.54,2.08,12.53,9.2,24.93,18.28,33.66a62.14,62.14,0,0,0,16,11.4,72.28,72.28,0,0,0,24.51,6.83,95.46,95.46,0,0,0,10.09.3h375.8c17.8-.06,35.67-7,47.89-20.18a63.19,63.19,0,0,0,11-15.73,73.35,73.35,0,0,0,6.71-24.16,66.07,66.07,0,0,0,.3-7.66V292.12c0-12.41-10.92-24.34-23.74-23.74s-23.74,10.45-23.74,23.8Z'
        />
      </svg>
    ),
    dobleSquares: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <path
          {...props}
          d='M464,0a48,48,0,0,1,48,48V336a48,48,0,0,1-48,48H176a48,48,0,0,1-48-48V48A48,48,0,0,1,176,0H464M176,416a80.09,80.09,0,0,1-80-80V128H48A48,48,0,0,0,0,176V464a48,48,0,0,0,48,48H336a48,48,0,0,0,48-48V416Z'
        />
      </svg>
    ),
    search: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 590'>
        <path
          {...props}
          d='M505,481.66,405.33,382a24,24,0,0,0-17-7H372a206.94,206.94,0,0,0,44-128c0-114.89-93.11-208-208-208S0,132.09,0,247,93.11,455,208,455a207,207,0,0,0,128-44v16.3a24,24,0,0,0,7,17L442.73,544a23.91,23.91,0,0,0,33.9,0l28.3-28.3A24.11,24.11,0,0,0,505,481.66ZM208,375A128,128,0,1,1,336,247,127.92,127.92,0,0,1,208,375Z'
        />
      </svg>
    ),
    pages: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 319.2 389.3'>
        <path
          fill='#808080'
          {...props}
          d='M247.8,65.6L191.6,9.4c-6-6-14.2-9.4-22.7-9.4H32.1C14.4,0.1,0,14.5,0,32.2v278.6C0,328.6,14.4,343,32.1,343
	H225c17.7,0,32.1-14.4,32.1-32.1V88.4C257.2,79.9,253.8,71.7,247.8,65.6z M232.6,80.8c1.4,1.4,2.3,3.1,2.8,5h-64v-64
	c1.9,0.5,3.5,1.4,5,2.8L232.6,80.8z M225,321.6H32.1c-5.9,0-10.7-4.8-10.7-10.7V32.2c0-5.9,4.8-10.7,10.7-10.7H150v69.7
	c0,8.9,7.2,16.1,16.1,16.1h69.7v203.6C235.8,316.7,230.9,321.6,225,321.6z'
        />

        <path
          fill='#ffffff'
          {...props}
          d='M63.5,65.7v263.6l6.9,42.4l34.6,6.4l191.6-4.3l13.8-16.5L307.2,170v-51.1c0,0-33.5-35.1-36.2-37.3
		s-45.8-37.8-45.8-37.8l-141,5.3l-11.2,2.1L63.5,65.7z'
        />

        <path
          fill='#808080'
          {...props}
          d='M309.5,104.4l-57.7-57.7c-6.2-6.2-14.6-9.7-23.3-9.7H88c-18.2,0.1-33,14.9-33,33.1v286.2c0,18.2,14.8,33,33,33
	h198.1c18.2,0,33-14.8,33-33V127.8C319.2,119.1,315.7,110.6,309.5,104.4z M293.9,120c1.4,1.4,2.4,3.2,2.9,5.1h-65.7V59.4
	c1.9,0.5,3.6,1.4,5.1,2.9L293.9,120z M286.2,367.3H88c-6.1,0-11-5-11-11V70.1c0-6.1,5-11,11-11h121.1v71.6c0,9.2,7.4,16.5,16.5,16.5
	h71.6v209.2C297.2,362.4,292.2,367.3,286.2,367.3z'
        />
      </svg>
    ),
    ellipsis: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <path
          fill='#404040'
          {...props}
          d='M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z'
        />
      </svg>
    ),
    share: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 227.216 227.216'>
        <path
          fill='#000000'
          {...props}
          d='M175.897,141.476c-13.249,0-25.11,6.044-32.98,15.518l-51.194-29.066c1.592-4.48,2.467-9.297,2.467-14.317
          c0-5.019-0.875-9.836-2.467-14.316l51.19-29.073c7.869,9.477,19.732,15.523,32.982,15.523c23.634,0,42.862-19.235,42.862-42.879
          C218.759,19.229,199.531,0,175.897,0C152.26,0,133.03,19.229,133.03,42.865c0,5.02,0.874,9.838,2.467,14.319L84.304,86.258
          c-7.869-9.472-19.729-15.514-32.975-15.514c-23.64,0-42.873,19.229-42.873,42.866c0,23.636,19.233,42.865,42.873,42.865
          c13.246,0,25.105-6.042,32.974-15.513l51.194,29.067c-1.593,4.481-2.468,9.3-2.468,14.321c0,23.636,19.23,42.865,42.867,42.865
          c23.634,0,42.862-19.23,42.862-42.865C218.759,160.71,199.531,141.476,175.897,141.476z M175.897,15
          c15.363,0,27.862,12.5,27.862,27.865c0,15.373-12.499,27.879-27.862,27.879c-15.366,0-27.867-12.506-27.867-27.879
          C148.03,27.5,160.531,15,175.897,15z M51.33,141.476c-15.369,0-27.873-12.501-27.873-27.865c0-15.366,12.504-27.866,27.873-27.866
          c15.363,0,27.861,12.5,27.861,27.866C79.191,128.975,66.692,141.476,51.33,141.476z M175.897,212.216
          c-15.366,0-27.867-12.501-27.867-27.865c0-15.37,12.501-27.875,27.867-27.875c15.363,0,27.862,12.505,27.862,27.875
          C203.759,199.715,191.26,212.216,175.897,212.216z'
        />
      </svg>
    )
  }[icon])

Icon.propTypes = {
  icon: PropTypes.string.isRequired
}
