import React from 'react'
import styled from 'styled-components'

export const Spinner = props => {
  return <Wrapper {...props} />
}

const Wrapper = styled.div`
  position: absolute;
  left: 27%;
  font-size: 0;
  width: 31px;
  height: 31px;
  border: 3px solid #ccc;
  border-radius: 50%;
  box-sizing: border-box;
  background: #080808;
  top: 11px;

  &:before {
    content: '';
    position: absolute;
    top: -3px;
    right: 0;
    left: -3px;
    margin: 0 auto;
    border-radius: 50%;
    border-top: 3px solid rgb(82, 82, 82);
    border-bottom: 3px solid transparent;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: loading 1s linear infinite;
  }

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
