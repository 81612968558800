import { createLocalStorageStateHook } from 'use-local-storage-state'

export const useAppHistoryState = createLocalStorageStateHook('history')
export const useCustomPageCacheState = createLocalStorageStateHook(
  'customPageCache'
)
export const useContentDividerCacheState = createLocalStorageStateHook(
  'contentDividerCache'
)
export const usePageholderCacheState = createLocalStorageStateHook(
  'pageholderCache'
)
