export const CUSTOM_PAGE_TYPE_FEEDS = 1
export const CUSTOM_PAGE_TYPE_TAGS = 2
export const CUSTOM_PAGE_TYPE_LINKS = 3
export const CUSTOM_PAGE_TYPE_CONTENT_SOURCES = 4
export const CUSTOM_PAGE_TYPE_LOGS = 5
export const CUSTOM_PAGE_TYPE_PICS = 6

export const customPageTypes = {
  [CUSTOM_PAGE_TYPE_FEEDS]: 'xFeeds',
  [CUSTOM_PAGE_TYPE_TAGS]: 'xTags',
  [CUSTOM_PAGE_TYPE_LINKS]: 'xLinks',
  [CUSTOM_PAGE_TYPE_CONTENT_SOURCES]: 'Content Source',
  [CUSTOM_PAGE_TYPE_LOGS]: 'xLogs',
  [CUSTOM_PAGE_TYPE_PICS]: 'xPics'
}

export const allCustomPageTypes = Object.keys(customPageTypes).map(Number)

export const SOCIAL_MEDIA_TYPE_FACEBOOK = 1
export const SOCIAL_MEDIA_TYPE_TWITTER = 2
export const SOCIAL_MEDIA_TYPE_INSTAGRAM = 3
export const SOCIAL_MEDIA_TYPE_YOUTUBE = 4
export const SOCIAL_MEDIA_TYPE_TWITTER_TAGS = 5
export const SOCIAL_MEDIA_TYPE_INSTAGRAM_TAGS = 6
export const SOCIAL_MEDIA_TYPE_YOUTUBE_TAGS = 7
export const SOCIAL_MEDIA_TYPE_VIDEO_LOGS = 8
export const SOCIAL_MEDIA_TYPE_IMAGE_LOGS = 9

export const socialMediaTypes = {
  [SOCIAL_MEDIA_TYPE_FACEBOOK]: 'Facebook',
  [SOCIAL_MEDIA_TYPE_YOUTUBE]: 'YouTube',
  [SOCIAL_MEDIA_TYPE_INSTAGRAM]: 'Instagram',
  [SOCIAL_MEDIA_TYPE_TWITTER]: 'Twitter',
  [SOCIAL_MEDIA_TYPE_YOUTUBE_TAGS]: 'YouTube',
  [SOCIAL_MEDIA_TYPE_INSTAGRAM_TAGS]: 'Instagram',
  [SOCIAL_MEDIA_TYPE_TWITTER_TAGS]: 'Twitter',
  [SOCIAL_MEDIA_TYPE_VIDEO_LOGS]: 'Twitter',
  [SOCIAL_MEDIA_TYPE_IMAGE_LOGS]: 'YouTube'
}

export const socialMediaIcons = {
  [SOCIAL_MEDIA_TYPE_FACEBOOK]: ['fab', 'facebook-f'],
  [SOCIAL_MEDIA_TYPE_YOUTUBE]: ['fab', 'youtube'],
  [SOCIAL_MEDIA_TYPE_INSTAGRAM]: ['fab', 'instagram'],
  [SOCIAL_MEDIA_TYPE_TWITTER]: ['fab', 'twitter']
}

export const LOG_MEDIA_TYPE_FACEBOOK = 1
export const LOG_MEDIA_TYPE_TWITTER = 2
export const LOG_MEDIA_TYPE_INSTAGRAM = 3
export const LOG_MEDIA_TYPE_YOUTUBE = 4
export const LOG_MEDIA_TYPE_IMAGE = 5
export const LOG_MEDIA_TYPE_VIDEO = 6

export const logMediaTypes = {
  [LOG_MEDIA_TYPE_FACEBOOK]: 'Facebook',
  [LOG_MEDIA_TYPE_TWITTER]: 'Twitter',
  [LOG_MEDIA_TYPE_INSTAGRAM]: 'Instagram',
  [LOG_MEDIA_TYPE_YOUTUBE]: 'YouTube',
  [LOG_MEDIA_TYPE_IMAGE]: 'Image',
  [LOG_MEDIA_TYPE_VIDEO]: 'Video'
}

export const normalizeMediaType = (looseMediaType = '') =>
  looseMediaType.toLowerCase().replace('tags', '').trim()

export const filterSocialMediaTypes = {
  [SOCIAL_MEDIA_TYPE_FACEBOOK]: 'Facebook',
  [SOCIAL_MEDIA_TYPE_YOUTUBE]: 'YouTube',
  [SOCIAL_MEDIA_TYPE_INSTAGRAM]: 'Instagram',
  [SOCIAL_MEDIA_TYPE_TWITTER]: 'Twitter'
}

export const allFilterSocialMediaTypes = Object.keys(
  filterSocialMediaTypes
).map(Number)

export const Latest = 0
export const MostView = 1
export const Popular = 2

export const customPagesFilter = {
  [MostView]: 'Most Views',
  [Latest]: 'Latest'
}

export const allCustomPagesFilter = Object.values(customPagesFilter)

export const backgroundColorDefault = 'rgba(0, 0, 0, .6)'

export const today = new Date()
export const version = `${today.getFullYear()}${today.getMonth()}${today.getDate()}${today.getHours()}`
