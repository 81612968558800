import { ColorMediaIcon } from 'atoms/ColorMediaIcon'
import { StatusIndicator } from 'atoms/StatusIndicator'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { socialMediaTypes } from 'util/constants'
import { AppContext } from 'util/contexts'
import { getAdvanceSearch } from 'util/queries'
import { useAPI } from 'util/useAPI'

export const AdvanceSearch = ({ values }) => {
  const { queryValue, setQueryValue, appShortName, t } = useContext(AppContext)
  const [mediaSelected, setMediaSelected] = useState({})
  const [results, setResults] = useState(undefined)

  const { status, data } = useAPI(
    ['advance-search', { values }],
    getAdvanceSearch,
    {
      appShortName,
      query: values
    },
    {
      enabled: !!values,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false
    }
  )

  useEffect(() => {
    if (data) {
      setResults(data?.data)
    }
    if (!values) {
      setResults(undefined)
      setMediaSelected({})
    }
  }, [data, setResults, values])

  const selectMediaType = media => {
    if (mediaSelected === media) {
      setMediaSelected({})
      setQueryValue({})
      setResults(data.data)
    } else {
      setQueryValue({
        ...queryValue,
        advancedQuery: media?.value || media?.username,
        mediaTypeId: media?.mediaTypeId
      })
      setMediaSelected(media)
      setResults(vl => vl.filter(i => i === media))
    }
  }

  return (
    <Wrapper>
      {status === 'loading' ? (
        <StatusIndicator status={status} loadingText='Searching' search />
      ) : (
        <>
          {results && (
            <>
              {results?.length ? (
                <List>
                  {results.map((media, index) => {
                    const iconString = socialMediaTypes[
                      media?.mediaTypeId
                    ]?.toLowerCase()
                    return (
                      <ListType
                        key={index}
                        onClick={() => selectMediaType(media)}
                        active={mediaSelected === media}
                      >
                        {media?.mediaTypeId && (
                          <ColorMediaIcon icon={iconString} />
                        )}
                        <span>{media?.value || media?.username}</span>
                      </ListType>
                    )
                  })}
                </List>
              ) : (
                <List>
                  <ListType active center noPointer>
                    {t('No results')}
                  </ListType>
                </List>
              )}
            </>
          )}
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: auto;
  flex: 0 0 100%;
  width: 100%;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0 0 7px;
  max-height: 215px;
  overflow: auto;

  svg {
    margin: 0 10px 0 0;
    background: white;
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    border-radius: 5px;

    path {
      background: linear-gradient(
        45deg,
        #405de6,
        #5851db,
        #833ab4,
        #c13584,
        #e1306c,
        #fd1d1d
      );
    }
  }
`

const ListType = styled.li`
  align-items: center;
  background: ${props => (props.active ? '#ffffff' : '#444')};
  border-radius: 5px;
  color: ${props => (props.active ? '#000000' : '#ffffff')};
  display: flex;
  margin: 0 auto 5px;
  padding: 8px 15px;
  width: 86%;
  cursor: ${props => (props.noPointer ? 'text' : 'pointer')};
  justify-content: ${props => (props.center ? 'center' : 'start')};

  &:after {
    content: '\u2715';
    display: ${props => (props.active ? 'block' : 'none')};
    margin-left: auto;
  }
`

AdvanceSearch.propTypes = {
  values: PropTypes.string.isRequired
}
