import PropTypes from 'prop-types'
import { slice } from 'ramda'
import React from 'react'

export const Truncate = ({ maxLength, text }) => {
  if (!text) return ''
  if (text.length < maxLength) return text

  return <span title={text}>{slice(0, maxLength - 1, text)}…</span>
}

Truncate.propTypes = {
  maxLength: PropTypes.number,
  text: PropTypes.string
}
