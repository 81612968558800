import { HexagonImg } from 'atoms/HexagonImage'
import { StatusIndicator } from 'atoms/StatusIndicator'
import { Truncate } from 'atoms/Truncate'
import logo from 'logo.svg'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from 'util/contexts'
import { size } from 'util/mediaBreakpoints'

export const MainViewHeader = ({ isMainView }) => {
  const { appProperties } = useContext(AppContext)
  return (
    <WrapperBar isMainView={isMainView}>
      {appProperties?.icon?.includes('undefined') ? (
        <StatusIndicator status='loading' />
      ) : (
        <Header>
          <HexagonImg
            background={
              appProperties?.icon?.includes('noImage') ||
              appProperties?.icon?.includes('undefined')
                ? logo
                : appProperties?.icon
            }
          />
          <span>
            <Truncate maxLength={30} text={appProperties?.name} />
          </span>
        </Header>
      )}
      <LogoImage>
        <img
          src={logo}
          onError={ref => (ref.target.src = logo)}
          alt='App icon'
        />
      </LogoImage>
    </WrapperBar>
  )
}
const LogoImage = styled.div`
  width: 15%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
  }
`
const Header = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: left;
  width: -webkit-fill-available;
  justify-content: left;
  align-items: center;
  max-height: 60px;

  img {
    max-width: 35px;
    margin: 0 10px 0 0;
    border-radius: 2px;
    max-height: 35px;
  }

  span {
    padding: 0 0 0 15px;
  }
`

const WrapperBar = styled.header`
  background: #000000;
  position: fixed;
  width: 100%;
  top: 0;
  justify-content: flex-start;
  box-sizing: border-box;
  color: white;
  align-items: center;
  display: flex;
  z-index: 2;
  padding: ${props => (props.isHidden ? '0' : '0 15px')};
  max-width: ${size.mobileL};

  svg {
    width: 10px;
    cursor: pointer;
    margin: 5px 0;
  }
`
