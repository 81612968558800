import { StatusIndicator } from 'atoms/StatusIndicator'
import React, { lazy, Suspense, useContext, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch, useLocation } from 'react-router-dom'
import { appInsights } from 'util/appInsights'
import { AppContext } from 'util/contexts'
import { getDesignApp } from 'util/queries'
import { useAPI } from 'util/useAPI'
import { useClientId } from 'util/useClientId'
import { useRoutes } from 'util/useRoutes'
import { DefaultViewPage } from './DefaultViewPage'
import { MainViewPage } from './MainViewPage'

const appImages = process.env.REACT_APP_BLOB_IMAGES_URL

const MainViewFilter = lazy(() => import('organisms/MainViewFilter'))

export const RootPage = () => {
  const { homeRoute, mainView, shareUrl } = useRoutes()
  const clientId = useClientId()
  const location = useLocation()
  const isMainView = location.pathname.includes('main-view')

  const {
    appShortName,
    xPagesPointer,
    setTitleProps,
    cache,
    setCache,
    setAppProperties
  } = useContext(AppContext)
  const hasPointer = useMemo(() => xPagesPointer?.length, [xPagesPointer])

  const { status: designStatus, data } = useAPI(
    ['app'],
    getDesignApp,
    {},
    {
      onSuccess: data => {
        if (cache !== null && !Array.isArray(cache)) {
          return setCache([data.data])
        }
        const pageIndex = cache?.findIndex(
          e => e.shortName.toLowerCase() === data.data.shortName.toLowerCase()
        )
        if (pageIndex !== -1) {
          const newCache = [...cache]

          return setCache(newCache.splice(pageIndex))
        }
        if (
          cache?.length &&
          cache[0].shortName?.toLowerCase() ===
            location.state?.fromPointer?.toLowerCase()
        ) {
          return setCache(prevArray => [data.data, ...prevArray])
        }
        return setCache([data.data])
      }
    }
  )

  const appProperties = data?.data || {}

  useEffect(() => {
    if (appProperties.backGroundImageUrl) {
      setAppProperties({
        background: appImages + appProperties.backGroundImageUrl,
        name: appProperties.name,
        icon: appImages + appProperties.iconUrl,
        description: appProperties.description
      })
      setTitleProps({
        textColor: appProperties.textColor,
        textTransparency: appProperties.textTransparency
      })
    }
  }, [appProperties, setTitleProps, setAppProperties])

  useEffect(() => {
    if (clientId) {
      appInsights.trackEvent({
        name: 'xPage viewed',
        properties: { clientId, appShortName }
      })
    }
  }, [clientId, appShortName])

  return (
    <>
      <Helmet>
        <title>{appProperties.name}</title>
        <meta name={appProperties.name} content={appProperties.name} />
        <link rel='apple-touch-icon' href={appProperties.iconUrl} />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='viewport' content='width = 425' />
        <link
          rel='shortcut icon'
          id='favicon'
          href={appImages + appProperties.iconUrl}
        />
      </Helmet>

      {hasPointer ? (
        <Route
          path={homeRoute()}
          component={DefaultViewPage}
          defaultParams={{ appShortName: appShortName }}
        />
      ) : (
        <Switch>
          <Route
            path={mainView()}
            component={MainViewPage}
            exact
            defaultParams={{ appShortName: appShortName }}
          />
          <Route
            path={shareUrl()}
            component={MainViewPage}
            exact
            defaultParams={{
              appShortName: appShortName
            }}
          />
          <Route
            path={homeRoute()}
            component={DefaultViewPage}
            defaultParams={{ appShortName: appShortName }}
          />
        </Switch>
      )}
      {designStatus === 'loading' && (
        <StatusIndicator status={designStatus} loadingText='Loading App' />
      )}

      {isMainView ? (
        <Suspense fallback={<StatusIndicator status='loading' />}>
          <MainViewFilter />
        </Suspense>
      ) : (
        ''
      )}
    </>
  )
}
