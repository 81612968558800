/*
  util/queries

  To use with react-query, all queries should be wrapped by util/useAPI.
  This way, context is injected. Also, queries are converted to thunks,
  so they are passed without being fired at every render.
*/

import _axios from 'axios'
import qs from 'qs'
import * as R from 'ramda'
import { useContext, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { AppContext } from './contexts'

const url = process.env.REACT_APP_API_URL

// Workaround for array URL parameters
const axios = _axios.create({
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
})

//
// API methods
//

export const getDesignApp = ({ appShortName: pageHolderShortName }) =>
  axios.get(`${url}/App/${pageHolderShortName}/Design`)

export const getNavigationItems = ({
  appShortName: pageHolderShortName,
  parentId
}) =>
  axios.get(`${url}/App/${pageHolderShortName}/ContentDividers`, {
    params: { parentId }
  })

export const getContentDividers = ({ appShortName: pageHolderShortName }) =>
  axios.get(`${url}/App/${pageHolderShortName}/content-dividers`)

export const getSingleContentDivider = ({ contentDividerId }) =>
  axios.get(`${url}/App/${contentDividerId}/ContentDivider`)

export const getDefaultView = ({ appShortName: pageHolderShortName }) =>
  axios.get(`${url}/App/${pageHolderShortName}/default-view`)

export const getTags = () => axios.get(`${url}/App/Tags`)

export const getMainView = ({
  appShortName: pageHolderShortName,
  take,
  endCursor,
  query,
  advancedQuery,
  mediaTypeId,
  sort,
  customPageId,
  shareCode
}) =>
  axios.get(`${url}/App/${pageHolderShortName}/main-view`, {
    params: {
      take,
      endCursor,
      query,
      advancedQuery,
      mediaTypeId,
      sort,
      customPageId,
      shareCode
    }
  })

export const getCategories = () => axios.get(`${url}/App/Categories`)

export const getSharePost = ({ shareCode }) =>
  axios.get(`${url}/App/share`, {
    params: { shareCode }
  })

export const getAbout = ({ appShortName: pageHolderShortName }) =>
  axios.get(`${url}/App/${pageHolderShortName}/about`)

export const getAdvanceSearch = ({
  appShortName: pageHolderShortName,
  query
}) =>
  axios.get(`${url}/App/${pageHolderShortName}/advance-search`, {
    params: { query }
  })

//
// Queries
//

export const useMainViewQuery = ({
  take,
  endCursor,
  query,
  advancedQuery,
  mediaTypeId,
  sort,
  customPageId,
  shareCode
}) => {
  const { appShortName } = useContext(AppContext)
  const [dt, setdt] = useState([])

  const retryDelay =
    R.last(dt)?.data?.posts?.length === 0 && advancedQuery ? 30000 : false

  return {
    ...useInfiniteQuery(
      [
        'mainViewQuery',
        {
          appShortName,
          take,
          query,
          endCursor,
          advancedQuery,
          mediaTypeId,
          sort,
          customPageId,
          shareCode
        }
      ],
      ({ pageParam = undefined }) => {
        return getMainView({
          appShortName,
          take,
          endCursor: pageParam,
          query,
          advancedQuery,
          mediaTypeId,
          sort,
          customPageId,
          shareCode
        })
      },
      {
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        structuralSharing: false,
        select: data => data?.pages,
        onSuccess: data => setdt(data),
        getNextPageParam: (lastPage, pages) => lastPage.data.endCursor,
        staleTime: retryDelay,
        refetchInterval: retryDelay
      }
    )
  }
}
