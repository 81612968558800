import { StatusIndicator } from 'atoms/StatusIndicator'
import React, { lazy, Suspense, useContext, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { withAppInsights } from 'util/appInsights'
import { AppContext } from 'util/contexts'
import { getDefaultView } from 'util/queries'
import { useAPI } from 'util/useAPI'

const XPagesAccordion = lazy(() => import('organisms/XPagesAccordion'))
const GoBack = lazy(() => import('atoms/GoBack'))
export const DefaultViewPage = withAppInsights('Default View Page', () => {
  const {
    xPagesPointer,
    setXPagesPointer,
    appProperties,
    titleProps
  } = useContext(AppContext)
  const {
    status: defaultViewStatus,
    data: defaultViewData,
    isLoading
  } = useAPI(['default-view'], getDefaultView)

  useEffect(() => {
    if (defaultViewData) {
      setXPagesPointer(defaultViewData?.data)
    }
  }, [defaultViewData, setXPagesPointer])
  if (!isLoading && !defaultViewData?.data.length) {
    const newPath = `${useLocation().pathname}/main-view`
    const pathname = newPath.replace(/(\b\S.+\b)(?=.*\1)/g, '').trim()
    return (
      <Redirect
        to={{
          pathname,
          state: { fromPointer: useLocation().state?.fromPointer }
        }}
      />
    )
  }

  return (
    <>
      <Suspense fallback={<StatusIndicator status='loading' />}>
        <GoBack />
      </Suspense>
      <Wrapper>
        <Title
          color={titleProps.textColor}
          opacity={titleProps.textTransparency}
        >
          {appProperties?.name}
        </Title>
        <Suspense fallback={<StatusIndicator status={defaultViewStatus} />}>
          <XPagesAccordion
            contentDividers={xPagesPointer}
            status={defaultViewStatus}
          />
        </Suspense>
      </Wrapper>
    </>
  )
})

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Title = styled.h1`
  font-size: 33px;
  text-align: center;
  font-weight: 500;
  text-shadow: 1px 1px 2.5px rgba(0, 0, 0, 0.4);
  color: ${props => props.color};
  opacity: ${props => props.opacity};
  margin-top: -55px;
  margin-bottom: 22px;
`
