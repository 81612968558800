import { buildRoute } from './buildRoute'

export const homeRoute = ({ appShortName = 0 }) =>
  buildRoute('/:appShortName', { appShortName })
export const settingsPageRoute = ({ appShortName = 0 }) =>
  buildRoute('/:appShortName/settings', { appShortName })
export const mainView = ({ appShortName = 0, contentDividerId = 0 }) =>
  buildRoute('/:appShortName/main-view', {
    appShortName,
    contentDividerId
  })
export const shareUrl = ({ appShortName, shareCode = null }) =>
  buildRoute('/:appShortName/:shareCode', {
    appShortName,
    shareCode
  })

// Home subroutes
export const customPageRoute = ({ appShortName = 0, customPageId = 0 }) =>
  buildRoute('/:appShortName/customPage/:customPageId', {
    appShortName,
    customPageId
  })
export const categoryRoute = ({ appShortName = 0, contentDividerId = 0 }) =>
  buildRoute('/:appShortName/categories/:contentDividerId', {
    appShortName,
    contentDividerId
  })
export const listRoute = ({ appShortName = 0, contentDividerId = 0 }) =>
  buildRoute('/:appShortName/list/:contentDividerId', {
    appShortName,
    contentDividerId
  })

// History subroutes
export const historyCustomPageRoute = ({
  appShortName = 0,
  customPageId = 0
}) =>
  buildRoute('/:appShortName/history/customPage/:customPageId', {
    appShortName,
    customPageId
  })
export const historyCategoryRoute = ({
  appShortName = 0,
  contentDividerId = 0
}) =>
  buildRoute('/:appShortName/history/categories/:contentDividerId', {
    appShortName,
    contentDividerId
  })
export const historyListRoute = ({ appShortName = 0, contentDividerId = 0 }) =>
  buildRoute('/:appShortName/history/list/:contentDividerId', {
    appShortName,
    contentDividerId
  })

export const contentFilterPageRoute = ({ appShortName = 0 }) =>
  buildRoute('/:appShortName/settings/content-filter', {
    appShortName
  })
export const aboutPageRoute = ({ appShortName = 0 }) =>
  buildRoute('/:appShortName/settings/about', { appShortName })

export const explorerPageRoute = ({ appShortName = 0 }) =>
  buildRoute('/:appShortName/explore', { appShortName })

export const categoryExplorerPageRoute = ({
  appShortName = 0,
  contentDividerId = 0
}) =>
  buildRoute('/:appShortName/explore/categoriesExplore/:contentDividerId', {
    appShortName,
    contentDividerId
  })

export const listExplorePageRoute = ({
  appShortName = 0,
  contentDividerId = 0
}) =>
  buildRoute('/:appShortName/explore/listExplore/:contentDividerId', {
    appShortName,
    contentDividerId
  })

export const searchPage = ({ appShortName = 0 }) =>
  buildRoute('/:appShortName/search', {
    appShortName
  })

export const searchByTags = ({ appShortName = 0 }) =>
  buildRoute('/:appShortName/search/tags', {
    appShortName
  })

export const searchByCategories = ({ appShortName = 0 }) =>
  buildRoute('/:appShortName/search/categories', {
    appShortName
  })

export const explorerListResults = ({ appShortName = 0 }) =>
  buildRoute('/:appShortName/search/list', {
    appShortName
  })
