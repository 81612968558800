import React from 'react'

const Logo = props => {
  return (
    <svg
      id='Lager_1'
      data-name='Lager 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 607.85 607.85'
      fill={props.fill || '#cccccc'}
    >
      <title>Social_XP_Logo</title>
      <path
        d='M469.54,223.23H352.26l-59,71.27H469.54c12.5,0,26.42,16,26.42,38.94S482,372.38,469.54,372.38H420.6l-58.06,71.27h107c53.87,0,97.69-49.44,97.69-110.21S523.41,223.23,469.54,223.23Z'
        transform='translate(-12.69 -9.98)'
      />
      <polygon points='275.05 496.2 438.81 299.77 341.44 299.77 178.5 495.71 275.05 496.2' />
      <polygon points='224.54 416.07 269.82 361.62 236.44 321.83 327.51 213.25 236.57 213.25 190.97 267.61 145.37 213.25 54.42 213.25 145.49 321.83 53.3 432.23 55.92 432.19 143.87 432.19 190.97 376.04 224.54 416.07' />
      <path
        d='M167.77,200.37c-9,10.76-19.22,14.74-31.68,14.74h-70L84,193.7h60.82l12.5-15H125.46c-15.64,0-13.87-6.44-7-14.63L141.08,137c9.27-11.12,19.57-15.33,32.17-15.33h60.86L216.24,143H164.52L152.6,157.31h31.58c13.29,0,15.62,4.68,6.24,15.91Z'
        transform='translate(-12.69 -9.98)'
      />
      <path
        d='M256.16,200.37c-8.69,10.41-18.67,14.74-31.27,14.74H187.66c-11.91,0-15.55-4.09-6.67-14.74l53.31-63.89c8.3-9.95,18.77-14.86,31.36-14.86H302.9c12,0,15.45,4.21,6.56,14.86Zm-23-6.67,42.17-50.55H257.25L215.07,193.7Z'
        transform='translate(-12.69 -9.98)'
      />
      <path
        d='M308.8,215.11H275.16c-12.6,0-15.64-4-6.66-14.74l53.31-63.89c9-10.77,18.76-14.86,31.36-14.86h33.64L368.94,143l-24.19.12L302.58,193.7h24.09Z'
        transform='translate(-12.69 -9.98)'
      />
      <path
        d='M346.89,215.11H318.38l78-93.49H424.9Z'
        transform='translate(-12.69 -9.98)'
      />
      <path
        d='M433.4,215.11H406L427,190H408.74l-21,25.16H359.23L424.45,137c9.27-11.12,19.71-15.33,32.31-15.33h54.65Zm11.28-46.34L466.16,143H447.89l-21.48,25.74Z'
        transform='translate(-12.69 -9.98)'
      />
      <path
        d='M529.22,215.11H444.61l78-93.49h28.51L491,193.7h56.09Z'
        transform='translate(-12.69 -9.98)'
      />
    </svg>
  )
}

export default Logo
