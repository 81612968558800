import PropTypes from 'prop-types'
import React from 'react'

export const MenuIcon = ({ icon, ...props }) =>
  ({
    home: (
      <svg
        height='24px'
        viewBox='0 0 512 512'
        width='24px'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill='#ffffff'
          d='m498.195312 222.695312c-.011718-.011718-.023437-.023437-.035156-.035156l-208.855468-208.847656c-8.902344-8.90625-20.738282-13.8125-33.328126-13.8125-12.589843 0-24.425781 4.902344-33.332031 13.808594l-208.746093 208.742187c-.070313.070313-.140626.144531-.210938.214844-18.28125 18.386719-18.25 48.21875.089844 66.558594 8.378906 8.382812 19.445312 13.238281 31.277344 13.746093.480468.046876.964843.070313 1.453124.070313h8.324219v153.699219c0 30.414062 24.746094 55.160156 55.167969 55.160156h81.710938c8.28125 0 15-6.714844 15-15v-120.5c0-13.878906 11.289062-25.167969 25.167968-25.167969h48.195313c13.878906 0 25.167969 11.289063 25.167969 25.167969v120.5c0 8.285156 6.714843 15 15 15h81.710937c30.421875 0 55.167969-24.746094 55.167969-55.160156v-153.699219h7.71875c12.585937 0 24.421875-4.902344 33.332031-13.808594 18.359375-18.371093 18.367187-48.253906.023437-66.636719zm0 0'
        />
      </svg>
    ),
    historyActive: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 590'>
        <path
          fill='#fff'
          d='M512,294.48c.31,141-114.79,256.41-255.79,256.52A255,255,0,0,1,95.38,494.33a24.7,24.7,0,0,1-1.86-36.75l11.66-11.66a24.81,24.81,0,0,1,32.93-2.06,189.85,189.85,0,1,0-12.28-287.28L178.27,209c10.43,10.43,3,28.18-11.66,28.18H16.52A16.56,16.56,0,0,1,0,220.68V70.59A16.49,16.49,0,0,1,28.18,58.92l51,51A254.92,254.92,0,0,1,256,39C397.21,39,511.69,153.37,512,294.48ZM325.26,375.83l10.12-13A24.8,24.8,0,0,0,331,328l-42-32.72V187.65a24.71,24.71,0,0,0-24.77-24.77H247.74A24.71,24.71,0,0,0,223,187.65V327.72l67.51,52.54A24.81,24.81,0,0,0,325.26,375.83Z'
        />
      </svg>
    ),
    historyInactive: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 590'>
        <path
          fill='#fff'
          d='M12.39,55.52H22.71A12.42,12.42,0,0,1,35.1,67.9v97.65A255.72,255.72,0,0,1,256.83,39c140.8.41,255.38,115.61,255.17,256.41C511.79,436.63,397.21,551,256,551A255.32,255.32,0,0,1,84.44,485a12.36,12.36,0,0,1-.52-18l7.33-7.33a12.46,12.46,0,0,1,17-.52A220,220,0,0,0,256,515.9c121.5,0,220.9-98.37,220.9-220.9,0-121.5-98.37-220.9-220.9-220.9A220.91,220.91,0,0,0,59.56,193.84H161a12.42,12.42,0,0,1,12.39,12.39v10.32A12.42,12.42,0,0,1,161,228.94H12.39A12.42,12.42,0,0,1,0,216.55V67.9A12.42,12.42,0,0,1,12.39,55.52Zm332,325.37,4.85-6.71a12.47,12.47,0,0,0-2.68-17.34l-74-53.68V150.48a12.42,12.42,0,0,0-12.39-12.39h-8.26a12.42,12.42,0,0,0-12.39,12.39V319.88l87.64,63.69a12.18,12.18,0,0,0,17.24-2.68Z'
        />
      </svg>
    ),
    imageviewActive: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 590'>
        <path
          fill='#fff'
          d='M426.67,437.22v14.22A42.68,42.68,0,0,1,384,494.11H42.67A42.68,42.68,0,0,1,0,451.44V223.89a42.68,42.68,0,0,1,42.67-42.67H56.89V366.11A71.21,71.21,0,0,0,128,437.22ZM512,366.11V138.56a42.68,42.68,0,0,0-42.67-42.67H128a42.68,42.68,0,0,0-42.67,42.67V366.11A42.68,42.68,0,0,0,128,408.78H469.33A42.68,42.68,0,0,0,512,366.11ZM227.56,181.22a42.67,42.67,0,1,1-42.67-42.67A42.68,42.68,0,0,1,227.56,181.22Zm-85.33,128,49.33-49.33a10.68,10.68,0,0,1,15.11,0L241.78,295,362.22,174.56a10.68,10.68,0,0,1,15.11,0l77.78,77.78v99.56H142.22Z'
        />
      </svg>
    ),
    imageviewInactive: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 590'>
        <path
          fill='#fff'
          d='M469.33,95.89H99.56a42.68,42.68,0,0,0-42.67,42.67v14.22H42.67A42.68,42.68,0,0,0,0,195.44v256a42.68,42.68,0,0,0,42.67,42.67H412.44a42.68,42.68,0,0,0,42.67-42.67V437.22h14.22A42.68,42.68,0,0,0,512,394.56v-256A42.68,42.68,0,0,0,469.33,95.89ZM426.67,451.44a14.26,14.26,0,0,1-14.22,14.22H42.67a14.26,14.26,0,0,1-14.22-14.22v-256a14.26,14.26,0,0,1,14.22-14.22H56.89V394.56a42.68,42.68,0,0,0,42.67,42.67H426.67Zm56.89-56.89a14.26,14.26,0,0,1-14.22,14.22H99.56a14.26,14.26,0,0,1-14.22-14.22v-256a14.26,14.26,0,0,1,14.22-14.22H469.33a14.26,14.26,0,0,1,14.22,14.22ZM156.44,245.22a49.78,49.78,0,1,0-49.78-49.78A49.81,49.81,0,0,0,156.44,245.22Zm0-71.11a21.33,21.33,0,1,1-21.33,21.33A21.4,21.4,0,0,1,156.44,174.11Zm214.22,20.44a21.25,21.25,0,0,0-30.13,0L256.09,279l-27.56-27.56a21.25,21.25,0,0,0-30.13,0l-78.22,78.22A21.32,21.32,0,0,0,114,344.78v24.89a10.7,10.7,0,0,0,10.67,10.67h320a10.7,10.7,0,0,0,10.67-10.67V287.89a21.32,21.32,0,0,0-6.22-15.11Zm56,157.33H142.22v-4.18l71.11-71.11L256,319.27l99.56-99.56,71.11,71.11Z'
        />
      </svg>
    ),
    search: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 590'>
        <path
          fill='#fff'
          d='M505,481.66,405.33,382a24,24,0,0,0-17-7H372a206.94,206.94,0,0,0,44-128c0-114.89-93.11-208-208-208S0,132.09,0,247,93.11,455,208,455a207,207,0,0,0,128-44v16.3a24,24,0,0,0,7,17L442.73,544a23.91,23.91,0,0,0,33.9,0l28.3-28.3A24.11,24.11,0,0,0,505,481.66ZM208,375A128,128,0,1,1,336,247,127.92,127.92,0,0,1,208,375Z'
        />
      </svg>
    ),
    settings: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 590'>
        <path
          fill='#fff'
          d='M505.46,359.27l-45.94-26.53a207.7,207.7,0,0,0,0-75.7l45.94-26.53a13,13,0,0,0,5.93-15.1,268.56,268.56,0,0,0-59-102,13,13,0,0,0-16-2.48l-45.94,26.53A203.5,203.5,0,0,0,325,99.61V46.67a12.92,12.92,0,0,0-10.14-12.62,271.08,271.08,0,0,0-117.75,0,12.92,12.92,0,0,0-10.14,12.62V99.72a209.92,209.92,0,0,0-65.56,37.85L75.54,111a12.81,12.81,0,0,0-16,2.48,266.94,266.94,0,0,0-59,102,12.9,12.9,0,0,0,5.93,15.1l45.94,26.53a207.7,207.7,0,0,0,0,75.7L6.53,359.37A13,13,0,0,0,.6,374.47a268.56,268.56,0,0,0,59,102,13,13,0,0,0,16,2.48l45.94-26.53A203.5,203.5,0,0,0,187,490.28v53.05a12.92,12.92,0,0,0,10.14,12.62,271.08,271.08,0,0,0,117.75,0,12.92,12.92,0,0,0,10.14-12.62V490.28a209.92,209.92,0,0,0,65.56-37.85L436.56,479a12.81,12.81,0,0,0,16-2.48,266.94,266.94,0,0,0,59-102A13.32,13.32,0,0,0,505.46,359.27ZM255.94,381.16a86.26,86.26,0,1,1,86.26-86.26A86.38,86.38,0,0,1,255.94,381.16Z'
        />
      </svg>
    )
  }[icon])

MenuIcon.propTypes = {
  icon: PropTypes.string.isRequired
}
