import { Icon } from 'atoms/Icon'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from 'util/contexts'

export const UnderConstruction = () => {
  const { t } = useContext(AppContext)
  return (
    <NoContentAlert>
      <Icon icon='tools' />
      <p>{t('This app is')}</p>
      <p>
        <span>{t('Under')}</span> {t('construction')}
      </p>
    </NoContentAlert>
  )
}

const NoContentAlert = styled.div`
  position: absolute;
  background: black;
  width: 100%;
  height: 100vh;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff00;
  flex-direction: column;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;

  span {
    color: white;
  }

  svg {
    max-width: 25%;
    margin: 0 0 1rem;
  }
`
