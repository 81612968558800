import { useContext } from 'react'
import { useQuery } from 'react-query'
import { AppContext } from 'util/contexts'

export const useAPI = (key, fetch, params = {}, config = {}) => {
  const { appShortName } = useContext(AppContext)
  return useQuery(
    key && [appShortName, ...key],
    () => fetch({ appShortName, ...params }),
    config
  )
}
